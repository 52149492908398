.container-asset {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  height: 90vh;
  padding: 24px;

  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: row;

  gap: 120px;
  .container-img {
    height: 100%;
    width: 50%;
    max-width: 550px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 40px;
    img {
      object-fit: cover;
      width: 100%;
      height: 50%;
      border-radius: 24px;
    }
    .loader {
      width: 100%;
      height: 50%;
      canvas {
        border-radius: 24px;
      }
    }
  }
  .container-txt {
    width: 50%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 40px;
    padding-top: 40px;
    h1 {
      font-family: $font-2;
      font-weight: bolder;
    }
    .container-btn {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      gap: 40px;
    }
  }
}

@media screen and (max-width: 600px) {
  .container-asset {
    flex-direction: column-reverse;
    height: auto;
    gap: 40px;
    .container-img {
      width: 90%;
      img {
      }
      .loader {
        canvas {
          width: 100% !important;
          height: auto !important;
        }
      }
    }
    .container-txt {
      width: 90%;
      padding-top: 0px;
      h1 {
      }
      .container-btn {
      }
    }
  }
}
