.content-about {
  padding-bottom: 60px;
  .partie {
    padding-top: 80px;
    .uls {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      ul {
        padding-top: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10px;
        width: 45%;
        li {
          list-style-type: square;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .content-about {
    .partie {
      .uls {
        flex-direction: column;
        align-items: center;
        ul {
          width: 90%;
          gap: 30px;
        }
      }
    }
  }
}