.update-form {

  form {
    display: flex;
    align-items:center;
    justify-content: center;
    flex-direction: column;
    align-self: center;
    gap: 30px;
    > div {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: row;
    gap: 30px;

      .label-input-group {
        display: flex;
        flex-direction: column;
        gap: 6px;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        input,
        textarea {
          padding: 10px 20px;
          border-radius: 10px;
          border: #a3a3a3 2px solid;
          font-family: $font-1;
          width: 100%;
        }
      }
    }
  }
}

.delete-form{
  .delete-form-btn{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
  }
}

@media screen and (max-width: 600px) {
  .update-form {

    form {
      > div {
        flex-direction: column;
        align-items: center;
      }
    }
  }



}