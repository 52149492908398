
  .add-asset{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 30px;
align-self: center;

.input-label-group{
gap: 8px;
display: flex;
flex-direction: column;
align-items: flex-start;
width: 100%;
}
select{
  padding: 10px 20px;
  border-radius: 10px;
  border: #a3a3a3 2px solid;
    font-family: "Montserrat", sans-serif;
}

}
