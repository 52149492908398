
.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 24px;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  height: 5vh;
  min-height: 50px;
  width: 100%;
  transition: flex-direction 0.3s ease;

  a {
    font-family: $font-2;
    font-weight: bolder;
    font-size: 1.25em;
    text-decoration: none;
    color: #333;
    margin: 5px;
    transition: color 0.3s ease;
  }
  a:hover{
    animation: scale-down-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  .navbar-toggle {
    display: none;
    cursor: pointer;
    font-size: 2em;
  }


  &.navbar-open {
    flex-direction: column;
    align-items: flex-start;

    a {
      width: 100%;
      text-align: left;
    }
  }



}


@media screen and (max-width: 600px) {
  .navbar a {
    display: none;
  }
  .navbar {
    width: 100%;
    max-width: 600px;
    display: flex;
justify-content: flex-end;
align-items: center;

  }
 .navbar .navbar-toggle {
    display: flex;
    cursor: pointer;
    align-self: center;
  }
  .navbar.navbar-open .navbar-toggle {
    display: flex;
    cursor: pointer;
    align-self: flex-end;
  }


  .navbar-toggle:hover {
    animation: scale-down-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }


  .navbar-open a {
    display: block;
  }
  .navbar.navbar-open {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: #ffffff;
    height: 100vh;
    gap: 20px;
    z-index: 2;
    position: fixed;
    a {
      width: 100%;
      text-align: center;
    }

    body {
      overflow: hidden; /* Désactive le défilement du corps entier lorsque le menu est ouvert */
    }

}}
