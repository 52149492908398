@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

@font-face {
  font-family: "font-1";
  src: url(./assets/fonts/Montserrat-Regular.ttf);
  // src: url(./assets/fonts/Inter-Regular.ttf);
  font-family: "font-2";
  // src: url(./assets/fonts/Montserrat-SemiBold.ttf);
  src: url(./assets/fonts/Felixti.TTF);

}

$font-1: "Montserrat", sans-serif;
$font-2: "font-2", sans-serif;

$color-1: #dfdfdf;
$color-2: salmon;
$color-3: #282c34;
$white: rgb(243, 243, 243);
$black: rgb(0, 0, 0);
$gry : rgb(175, 172, 172);

$violetclair: #CEC4FF;
$violetfonce: #8800f7;
$greyNav :#c5c5c5;

@mixin verticalCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: $white;
  font-family: $font-1;
  // @include verticalCenter;
  font-size: 16pt;
  height: 100%;
  min-height: 100vh;
}
#root{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  .content{
  min-height: 90vh;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 40px;
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  }
}


li {
  list-style-type: none;
}

body {
  font-size: 10pt;
}

p{
  font-size: 1em;
}
a{
text-decoration: none;
color: #000;
}
h1 {
  font-size: 2.5em;
}

h2 {
  font-size: 2.25em;
}

h3 {
  font-size: 2em;
}

h4 {
  font-size: 1.75em;
}

h5 {
  font-size: 1.5em;
}

h6 {
  font-size: 1.25em;
}

button{
padding: 10px 20px;
border-radius: 10px;
border: #a3a3a3 2px solid;
background-image: url("assets/img/btn.png") ;
object-fit: fill;
font-family: $font-2;
font-weight: bolder;
}
button:hover{
  animation: scale-down-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

input, textarea{
  padding: 10px 20px;
  border-radius: 10px;
  border: #a3a3a3 2px solid;
  font-family: $font-1;
  width: 100%;
}
@keyframes scale-down-center {
  0% {
            transform: scale(1);
  }
  100% {
            transform: scale(0.90);
  }
}
@media (max-width: 480px) {
  body {
    font-size: 10pt;

  }
}

@media (min-width: 481px) and (max-width: 767px) {
  body {
    font-size: 10pt;

  }
}

@media (min-width: 768px) and (max-width: 991px) {
  body {
    font-size: 10pt;

  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  body {
    font-size: 10pt;

  }
}
@media screen and (max-width: 600px) {
#root .content{
padding: 20px;
max-width: 600px;
h1{
  text-align: center;
  }
}

}