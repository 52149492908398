.content-home {
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  .intro{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    img{
      width: 640px;
      }
  }
h6{
margin-top: 20px;
}

}
@media screen and (max-width: 600px) {
  .content-home {
   width: 100%;

    .intro{
     width: 100%;
     text-align: center;
      img{
        width: 80%;
        }
    }
  h6{
  margin-top: 20px;
  }
  h4{
    margin-bottom: 14px;
    }

  }
}