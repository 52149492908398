.video-presentation {
  .videos-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
    .video-container {
      width: 50%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 24px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      background-color: $white;
      border-radius: 24px;
      height: 400px;
      iframe {
        border-radius: 24px 24px 0 0;
        width: 100%;
        height: 80%;
      }
      p {
        padding: 0 24px 24px 24px;
        height: 20%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .video-presentation {
    .videos-container {
      flex-direction: column;
      align-items: center;
      .video-container {
        width: 90%;
        height: auto;
        iframe {
          height: 50%;
        }
      }
    }
  }
}
