.div-assets-container {
  padding: 40px;
  width: 100%;
  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      label {
        font-size: medium;
      }
    }
  }

  .assets-container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    gap: 24px;
    flex-wrap: wrap;
    justify-content: flex-start;
    .div-asset-preview {
      width: 23%;
      min-width: 250px;
      height: 300px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      background-color: $white;
      border-radius: 24px;

      img {
        width: 100%;
        min-height: 200px;
        object-fit: cover;
        border-radius: 24px 24px 0 0;
      }

      .txt-asset {
        padding: 12px 24px 24px 24px;

        height: 40%;
        width: 100%;
        .small {
          padding-top: 8px;
          font-size: 0.8em;
        }
      }
    }
    .div-asset-preview:hover {
      animation: scale-down-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }
  }
}

@media screen and (max-width: 600px) {
  .div-assets-container {
    ul {
    flex-wrap: wrap;
      width: 100%;
      max-width: 600px;
      gap: 10px;
      li {
        label {
        }
      }
    }

    .assets-container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .div-asset-preview {
        width: 80%;
        img {
        }

        .txt-asset {
        }
      }
      .div-asset-preview:hover {
      }
    }
  }
}
